/* tslint:disable */
/* eslint-disable */
/**
 * BackendFramework
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { SemanticDomainFull } from "../models";
// @ts-ignore
import { SemanticDomainTreeNode } from "../models";
/**
 * SemanticDomainApi - axios parameter creator
 * @export
 */
export const SemanticDomainApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSemanticDomainNames: async (
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/semanticdomain/allDomainNames`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSemanticDomainTreeNodes: async (
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/semanticdomain/domainGetAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainFull: async (
      id?: string,
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/semanticdomain/domainFull`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainTreeNode: async (
      id?: string,
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/semanticdomain/domainTreeNode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainTreeNodeByName: async (
      name?: string,
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/semanticdomain/domainByName`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SemanticDomainApi - functional programming interface
 * @export
 */
export const SemanticDomainApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SemanticDomainApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSemanticDomainNames(
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<{ [key: string]: string }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllSemanticDomainNames(
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSemanticDomainTreeNodes(
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SemanticDomainTreeNode>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllSemanticDomainTreeNodes(
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSemanticDomainFull(
      id?: string,
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SemanticDomainFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSemanticDomainFull(
          id,
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSemanticDomainTreeNode(
      id?: string,
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SemanticDomainTreeNode>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSemanticDomainTreeNode(
          id,
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSemanticDomainTreeNodeByName(
      name?: string,
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SemanticDomainTreeNode>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSemanticDomainTreeNodeByName(
          name,
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SemanticDomainApi - factory interface
 * @export
 */
export const SemanticDomainApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SemanticDomainApiFp(configuration);
  return {
    /**
     *
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSemanticDomainNames(
      lang?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: string }> {
      return localVarFp
        .getAllSemanticDomainNames(lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSemanticDomainTreeNodes(
      lang?: string,
      options?: any
    ): AxiosPromise<SemanticDomainTreeNode> {
      return localVarFp
        .getAllSemanticDomainTreeNodes(lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainFull(
      id?: string,
      lang?: string,
      options?: any
    ): AxiosPromise<SemanticDomainFull> {
      return localVarFp
        .getSemanticDomainFull(id, lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainTreeNode(
      id?: string,
      lang?: string,
      options?: any
    ): AxiosPromise<SemanticDomainTreeNode> {
      return localVarFp
        .getSemanticDomainTreeNode(id, lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainTreeNodeByName(
      name?: string,
      lang?: string,
      options?: any
    ): AxiosPromise<SemanticDomainTreeNode> {
      return localVarFp
        .getSemanticDomainTreeNodeByName(name, lang, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAllSemanticDomainNames operation in SemanticDomainApi.
 * @export
 * @interface SemanticDomainApiGetAllSemanticDomainNamesRequest
 */
export interface SemanticDomainApiGetAllSemanticDomainNamesRequest {
  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetAllSemanticDomainNames
   */
  readonly lang?: string;
}

/**
 * Request parameters for getAllSemanticDomainTreeNodes operation in SemanticDomainApi.
 * @export
 * @interface SemanticDomainApiGetAllSemanticDomainTreeNodesRequest
 */
export interface SemanticDomainApiGetAllSemanticDomainTreeNodesRequest {
  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetAllSemanticDomainTreeNodes
   */
  readonly lang?: string;
}

/**
 * Request parameters for getSemanticDomainFull operation in SemanticDomainApi.
 * @export
 * @interface SemanticDomainApiGetSemanticDomainFullRequest
 */
export interface SemanticDomainApiGetSemanticDomainFullRequest {
  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetSemanticDomainFull
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetSemanticDomainFull
   */
  readonly lang?: string;
}

/**
 * Request parameters for getSemanticDomainTreeNode operation in SemanticDomainApi.
 * @export
 * @interface SemanticDomainApiGetSemanticDomainTreeNodeRequest
 */
export interface SemanticDomainApiGetSemanticDomainTreeNodeRequest {
  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetSemanticDomainTreeNode
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetSemanticDomainTreeNode
   */
  readonly lang?: string;
}

/**
 * Request parameters for getSemanticDomainTreeNodeByName operation in SemanticDomainApi.
 * @export
 * @interface SemanticDomainApiGetSemanticDomainTreeNodeByNameRequest
 */
export interface SemanticDomainApiGetSemanticDomainTreeNodeByNameRequest {
  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetSemanticDomainTreeNodeByName
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof SemanticDomainApiGetSemanticDomainTreeNodeByName
   */
  readonly lang?: string;
}

/**
 * SemanticDomainApi - object-oriented interface
 * @export
 * @class SemanticDomainApi
 * @extends {BaseAPI}
 */
export class SemanticDomainApi extends BaseAPI {
  /**
   *
   * @param {SemanticDomainApiGetAllSemanticDomainNamesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SemanticDomainApi
   */
  public getAllSemanticDomainNames(
    requestParameters: SemanticDomainApiGetAllSemanticDomainNamesRequest = {},
    options?: any
  ) {
    return SemanticDomainApiFp(this.configuration)
      .getAllSemanticDomainNames(requestParameters.lang, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SemanticDomainApiGetAllSemanticDomainTreeNodesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SemanticDomainApi
   */
  public getAllSemanticDomainTreeNodes(
    requestParameters: SemanticDomainApiGetAllSemanticDomainTreeNodesRequest = {},
    options?: any
  ) {
    return SemanticDomainApiFp(this.configuration)
      .getAllSemanticDomainTreeNodes(requestParameters.lang, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SemanticDomainApiGetSemanticDomainFullRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SemanticDomainApi
   */
  public getSemanticDomainFull(
    requestParameters: SemanticDomainApiGetSemanticDomainFullRequest = {},
    options?: any
  ) {
    return SemanticDomainApiFp(this.configuration)
      .getSemanticDomainFull(
        requestParameters.id,
        requestParameters.lang,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SemanticDomainApiGetSemanticDomainTreeNodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SemanticDomainApi
   */
  public getSemanticDomainTreeNode(
    requestParameters: SemanticDomainApiGetSemanticDomainTreeNodeRequest = {},
    options?: any
  ) {
    return SemanticDomainApiFp(this.configuration)
      .getSemanticDomainTreeNode(
        requestParameters.id,
        requestParameters.lang,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SemanticDomainApiGetSemanticDomainTreeNodeByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SemanticDomainApi
   */
  public getSemanticDomainTreeNodeByName(
    requestParameters: SemanticDomainApiGetSemanticDomainTreeNodeByNameRequest = {},
    options?: any
  ) {
    return SemanticDomainApiFp(this.configuration)
      .getSemanticDomainTreeNodeByName(
        requestParameters.name,
        requestParameters.lang,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
