/* tslint:disable */
/* eslint-disable */
/**
 * BackendFramework
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ReasonType {
  Annotations = "Annotations",
  Etymologies = "Etymologies",
  Examples = "Examples",
  Field = "Field",
  GramInfoTrait = "GramInfoTrait",
  Illustrations = "Illustrations",
  NoteWithType = "NoteWithType",
  Notes = "Notes",
  Relations = "Relations",
  Reversals = "Reversals",
  Subsenses = "Subsenses",
  Trait = "Trait",
  TraitAnthroCode = "TraitAnthroCode",
  TraitDialectLabels = "TraitDialectLabels",
  TraitDomainType = "TraitDomainType",
  TraitDoNotPublishIn = "TraitDoNotPublishIn",
  TraitDoNotUseForParsing = "TraitDoNotUseForParsing",
  TraitEntryType = "TraitEntryType",
  TraitExcludeAsHeadword = "TraitExcludeAsHeadword",
  TraitMinorEntryCondition = "TraitMinorEntryCondition",
  TraitMorphType = "TraitMorphType",
  TraitPublishIn = "TraitPublishIn",
  TraitSenseType = "TraitSenseType",
  TraitStatus = "TraitStatus",
  TraitUsageType = "TraitUsageType",
  Variants = "Variants",
}
