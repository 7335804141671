/* tslint:disable */
/* eslint-disable */
/**
 * BackendFramework
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { ChartRootData } from "../models";
// @ts-ignore
import { SemanticDomainCount } from "../models";
// @ts-ignore
import { SemanticDomainUserCount } from "../models";
// @ts-ignore
import { WordsPerDayPerUserCount } from "../models";
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLineChartRootData: async (
      projectId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getLineChartRootData", "projectId", projectId);
      const localVarPath =
        `/v1/projects/{projectId}/statistics/GetLineChartRootData`.replace(
          `{${"projectId"}}`,
          encodeURIComponent(String(projectId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgressEstimationLineChartRoot: async (
      projectId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists(
        "getProgressEstimationLineChartRoot",
        "projectId",
        projectId
      );
      const localVarPath =
        `/v1/projects/{projectId}/statistics/GetProgressEstimationLineChartRoot`.replace(
          `{${"projectId"}}`,
          encodeURIComponent(String(projectId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainCounts: async (
      projectId: string,
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getSemanticDomainCounts", "projectId", projectId);
      const localVarPath =
        `/v1/projects/{projectId}/statistics/GetSemanticDomainCounts`.replace(
          `{${"projectId"}}`,
          encodeURIComponent(String(projectId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainUserCounts: async (
      projectId: string,
      lang?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getSemanticDomainUserCounts", "projectId", projectId);
      const localVarPath =
        `/v1/projects/{projectId}/statistics/GetSemanticDomainUserCounts`.replace(
          `{${"projectId"}}`,
          encodeURIComponent(String(projectId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lang !== undefined) {
        localVarQueryParameter["lang"] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWordsPerDayPerUserCounts: async (
      projectId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getWordsPerDayPerUserCounts", "projectId", projectId);
      const localVarPath =
        `/v1/projects/{projectId}/statistics/GetWordsPerDayPerUserCounts`.replace(
          `{${"projectId"}}`,
          encodeURIComponent(String(projectId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StatisticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLineChartRootData(
      projectId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartRootData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLineChartRootData(
          projectId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProgressEstimationLineChartRoot(
      projectId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartRootData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProgressEstimationLineChartRoot(
          projectId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSemanticDomainCounts(
      projectId: string,
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SemanticDomainCount>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSemanticDomainCounts(
          projectId,
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSemanticDomainUserCounts(
      projectId: string,
      lang?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SemanticDomainUserCount>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSemanticDomainUserCounts(
          projectId,
          lang,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWordsPerDayPerUserCounts(
      projectId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<WordsPerDayPerUserCount>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWordsPerDayPerUserCounts(
          projectId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StatisticsApiFp(configuration);
  return {
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLineChartRootData(
      projectId: string,
      options?: any
    ): AxiosPromise<ChartRootData> {
      return localVarFp
        .getLineChartRootData(projectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgressEstimationLineChartRoot(
      projectId: string,
      options?: any
    ): AxiosPromise<ChartRootData> {
      return localVarFp
        .getProgressEstimationLineChartRoot(projectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainCounts(
      projectId: string,
      lang?: string,
      options?: any
    ): AxiosPromise<Array<SemanticDomainCount>> {
      return localVarFp
        .getSemanticDomainCounts(projectId, lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {string} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSemanticDomainUserCounts(
      projectId: string,
      lang?: string,
      options?: any
    ): AxiosPromise<Array<SemanticDomainUserCount>> {
      return localVarFp
        .getSemanticDomainUserCounts(projectId, lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWordsPerDayPerUserCounts(
      projectId: string,
      options?: any
    ): AxiosPromise<Array<WordsPerDayPerUserCount>> {
      return localVarFp
        .getWordsPerDayPerUserCounts(projectId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getLineChartRootData operation in StatisticsApi.
 * @export
 * @interface StatisticsApiGetLineChartRootDataRequest
 */
export interface StatisticsApiGetLineChartRootDataRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetLineChartRootData
   */
  readonly projectId: string;
}

/**
 * Request parameters for getProgressEstimationLineChartRoot operation in StatisticsApi.
 * @export
 * @interface StatisticsApiGetProgressEstimationLineChartRootRequest
 */
export interface StatisticsApiGetProgressEstimationLineChartRootRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetProgressEstimationLineChartRoot
   */
  readonly projectId: string;
}

/**
 * Request parameters for getSemanticDomainCounts operation in StatisticsApi.
 * @export
 * @interface StatisticsApiGetSemanticDomainCountsRequest
 */
export interface StatisticsApiGetSemanticDomainCountsRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetSemanticDomainCounts
   */
  readonly projectId: string;

  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetSemanticDomainCounts
   */
  readonly lang?: string;
}

/**
 * Request parameters for getSemanticDomainUserCounts operation in StatisticsApi.
 * @export
 * @interface StatisticsApiGetSemanticDomainUserCountsRequest
 */
export interface StatisticsApiGetSemanticDomainUserCountsRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetSemanticDomainUserCounts
   */
  readonly projectId: string;

  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetSemanticDomainUserCounts
   */
  readonly lang?: string;
}

/**
 * Request parameters for getWordsPerDayPerUserCounts operation in StatisticsApi.
 * @export
 * @interface StatisticsApiGetWordsPerDayPerUserCountsRequest
 */
export interface StatisticsApiGetWordsPerDayPerUserCountsRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticsApiGetWordsPerDayPerUserCounts
   */
  readonly projectId: string;
}

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
  /**
   *
   * @param {StatisticsApiGetLineChartRootDataRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getLineChartRootData(
    requestParameters: StatisticsApiGetLineChartRootDataRequest,
    options?: any
  ) {
    return StatisticsApiFp(this.configuration)
      .getLineChartRootData(requestParameters.projectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StatisticsApiGetProgressEstimationLineChartRootRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getProgressEstimationLineChartRoot(
    requestParameters: StatisticsApiGetProgressEstimationLineChartRootRequest,
    options?: any
  ) {
    return StatisticsApiFp(this.configuration)
      .getProgressEstimationLineChartRoot(requestParameters.projectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StatisticsApiGetSemanticDomainCountsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getSemanticDomainCounts(
    requestParameters: StatisticsApiGetSemanticDomainCountsRequest,
    options?: any
  ) {
    return StatisticsApiFp(this.configuration)
      .getSemanticDomainCounts(
        requestParameters.projectId,
        requestParameters.lang,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StatisticsApiGetSemanticDomainUserCountsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getSemanticDomainUserCounts(
    requestParameters: StatisticsApiGetSemanticDomainUserCountsRequest,
    options?: any
  ) {
    return StatisticsApiFp(this.configuration)
      .getSemanticDomainUserCounts(
        requestParameters.projectId,
        requestParameters.lang,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StatisticsApiGetWordsPerDayPerUserCountsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getWordsPerDayPerUserCounts(
    requestParameters: StatisticsApiGetWordsPerDayPerUserCountsRequest,
    options?: any
  ) {
    return StatisticsApiFp(this.configuration)
      .getWordsPerDayPerUserCounts(requestParameters.projectId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
