/* tslint:disable */
/* eslint-disable */
/**
 * BackendFramework
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { EmailInviteData } from "../models";
// @ts-ignore
import { EmailInviteStatus } from "../models";
/**
 * InviteApi - axios parameter creator
 * @export
 */
export const InviteApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {EmailInviteData} emailInviteData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailInviteToProject: async (
      emailInviteData: EmailInviteData,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'emailInviteData' is not null or undefined
      assertParamExists(
        "emailInviteToProject",
        "emailInviteData",
        emailInviteData
      );
      const localVarPath = `/v1/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        emailInviteData,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateToken: async (
      projectId: string,
      token: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("validateToken", "projectId", projectId);
      // verify required parameter 'token' is not null or undefined
      assertParamExists("validateToken", "token", token);
      const localVarPath = `/v1/invite/{projectId}/validate/{token}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"token"}}`, encodeURIComponent(String(token)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InviteApi - functional programming interface
 * @export
 */
export const InviteApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InviteApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {EmailInviteData} emailInviteData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emailInviteToProject(
      emailInviteData: EmailInviteData,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.emailInviteToProject(
          emailInviteData,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateToken(
      projectId: string,
      token: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<EmailInviteStatus>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateToken(
        projectId,
        token,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InviteApi - factory interface
 * @export
 */
export const InviteApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InviteApiFp(configuration);
  return {
    /**
     *
     * @param {EmailInviteData} emailInviteData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailInviteToProject(
      emailInviteData: EmailInviteData,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .emailInviteToProject(emailInviteData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateToken(
      projectId: string,
      token: string,
      options?: any
    ): AxiosPromise<EmailInviteStatus> {
      return localVarFp
        .validateToken(projectId, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for emailInviteToProject operation in InviteApi.
 * @export
 * @interface InviteApiEmailInviteToProjectRequest
 */
export interface InviteApiEmailInviteToProjectRequest {
  /**
   *
   * @type {EmailInviteData}
   * @memberof InviteApiEmailInviteToProject
   */
  readonly emailInviteData: EmailInviteData;
}

/**
 * Request parameters for validateToken operation in InviteApi.
 * @export
 * @interface InviteApiValidateTokenRequest
 */
export interface InviteApiValidateTokenRequest {
  /**
   *
   * @type {string}
   * @memberof InviteApiValidateToken
   */
  readonly projectId: string;

  /**
   *
   * @type {string}
   * @memberof InviteApiValidateToken
   */
  readonly token: string;
}

/**
 * InviteApi - object-oriented interface
 * @export
 * @class InviteApi
 * @extends {BaseAPI}
 */
export class InviteApi extends BaseAPI {
  /**
   *
   * @param {InviteApiEmailInviteToProjectRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public emailInviteToProject(
    requestParameters: InviteApiEmailInviteToProjectRequest,
    options?: any
  ) {
    return InviteApiFp(this.configuration)
      .emailInviteToProject(requestParameters.emailInviteData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {InviteApiValidateTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public validateToken(
    requestParameters: InviteApiValidateTokenRequest,
    options?: any
  ) {
    return InviteApiFp(this.configuration)
      .validateToken(
        requestParameters.projectId,
        requestParameters.token,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
