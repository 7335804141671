export enum Path {
  AppRoot = "/app",
  DataEntry = "/app/data-entry",
  GoalCurrent = "/app/goals/current",
  GoalNext = "/app/goals/next",
  Goals = "/app/goals",
  Login = "/login",
  ProjInvite = "/invite",
  ProjScreen = "/app/projects",
  ProjSettings = "/app/project-settings",
  PwRequest = "/forgot/request",
  PwReset = "/forgot/reset",
  Root = "/",
  Signup = "/signup",
  SiteSettings = "/app/site-settings",
  Statistics = "/app/statistics",
  UserSettings = "/app/user-settings",
}
