/* tslint:disable */
/* eslint-disable */
/**
 * BackendFramework
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum GramCatGroup {
  Adjective = "Adjective",
  Adposition = "Adposition",
  Adverb = "Adverb",
  Classifier = "Classifier",
  Connective = "Connective",
  Determiner = "Determiner",
  ExistentialMarker = "ExistentialMarker",
  Expletive = "Expletive",
  Interjection = "Interjection",
  Noun = "Noun",
  Participle = "Participle",
  Particle = "Particle",
  Prenoun = "Prenoun",
  Preverb = "Preverb",
  ProForm = "ProForm",
  Verb = "Verb",
  Other = "Other",
  Unspecified = "Unspecified",
}
